<template>
  <div class="container-fluid p-0">
    <div class="d-none d-lg-block">
       <div class="row d-none">
          <div class="col-lg-8 position-relative" :style='{maxHeight: "1000px" }'>
             <div class="row  clLight pw7 justify-content-center">
                <div class="col-12 col-lg-10">
                    <p class='t3'>VAULT AUDIT SUMMARY</p>
              <p class='t4 mt-5 px-lg-5'> 
              The vaults and security deposit boxes which store the physical gold backing the circulating supply of
FUSEG Tokens are fully audited tri-annually. This ensures that the circulating supply of
FUSEG is always backed 100% and more by physical gold. This provides our clients with piece of
mind and full transparency. The Proof of Reserve (PoR) solution offers total transparency via 24/7
real-time blockchain verifiable auditing. This means at any moment in time, anyone can verify the
physical gold backing the circulating supply of FUSEG.</p>

              <div class='d-none d-md-block' style='margin-top: 100px;'>
                  <span class=' float-left f20 r-now px-lg-5'> READ THE FULL AUDIT </span>
                  <hr class='d-none d-lg-block w-100 float-right line' style='margin-top: -15px;margin-right: -110px;'>
                  
              </div>
                </div>   
            </div>   
          </div>

          <div class="col-md-4 d-none d-lg-block" style='position:relative;'>
              <img src="@/assets/images/im4low.jpg" 
              class='imgMd4' alt="" srcset="">
          </div>

          <div class="col-12 px-3 d-block d-lg-none">
              <img src="@/assets/images/im4low.jpg" class='d-block mx-auto imgSM'  alt="" srcset="">
          </div>
      </div>
      <div class="row">
        <div class="col-12 mtt-10 p-0">
          <div class="clLight d-inline-block position-relative htone" >
            <div class="position-absolute">
              <div class="tt-one-cont" style="margin-top:10%;">
              <h3 class="tt-one-c">VAULT AUDIT <br> SUMMARY</h3>
              </div>
              <div class="desc-one-cont">
              <p class="desc-one-c justify-text" style="max-width:80%;">
              
    The vaults and security deposit boxes which store the physical gold backing the circulating supply of FUSE G Tokens are fully audited tri-annually by TBD. This ensures that the circulating supply of FUSE G is always backed 100% and more by physical gold. This provides our clients with piece of mind and full transparency. The online audit reports are available to view online at the click of a button at any time.
              <br>
             
             
              </p>
              <div>
                <span class="position-relative" style="height: 49px;margin-top:165px;
    display: block;">
             <span @click="loadPop" class='position-absolute pt-link'
             style="
             background: rgb(225, 225, 225);
    height: 34px;
    cursor: pointer;
    width: 400px;
    z-index: 999;
    top: 8px;
    font-size: 151%;"
             > READ THE FULL AUDIT </span>
                  <hr class='position-relative d-none d-lg-block float-right line' style="width:99%;left:31%;top:10px;"/>
                     
              </span>
              </div>
            </div>
            </div>
          </div>
          <div class="d-inline-block position-relative" style="width:30%;">
            <img src="@/assets/images/im4low.jpg" class="mw-100p" height="auto" width="749" alt="" 
            style="margin-top: -33%;margin-left: -31%;"
             srcset="">
          </div>
        </div>
      </div>
    </div>
      
      <div class="row d-lg-none p-0 m-0">
          <div class="col-12 position-relative p-0" :style='{maxHeight: "1000px" }'>
             <div class="clLight mx-0 mb-5">
                <div class="" style="width: 85%;
    padding: 9% 0% 0% 5%;
    margin-left: 7%;">
                    <p class='t3m mb-3'>VAULT AUDIT SUMMARY</p>

                    <p class='t4m'> 
                        The vaults and security deposit boxes which store the physical gold backing the circulating supply of FUSE G Tokens are fully audited tri-annually by TBD. This ensures that the circulating supply of FUSE G is always backed 100% and more by physical gold. This provides our clients with piece of mind and full transparency. The online audit reports are available to view online at the click of a button at any time.
                    </p>

                </div>
                <div class="pl-4" style="margin-bottom:-37px;">
                    
                    <div class="d-inline-block" style="width: 50%;
    
    margin-top: 13%;">
                    <button class="btn button-light float-left" style="width: 90%;
    font-size: 2.5vw;
    padding: 7px 5px;" @click="loadPop">READ THE FULL AUDIT</button>
                    <span class="gold-line float-left" style="width: 10%;margin-top: 3vw;"></span>
                    </div>
                    <div class="d-inline-block" style="width:50%;float:right;">
                        <img src="@/assets/images/im4low.jpg" class="mw-100" >
                    </div>
                </div>   
            </div>   
          </div>

      </div>
  </div>
</template>
<script>
export default {
    props:['data'],
name: "ScCardRegister",
methods:{
    loadPop(){
     this.$root.Toast.fire({icon: 'info',title: "Coming Soon"})
    }
}
}
</script>

<style scoped>
.t3m {
        font-size: 7vw;
    max-width: 600px;
    letter-spacing: 2px;
    line-height: 8vw;
    color: #b99653;
    text-align: left;
    font-family: "Times New Roman" !important;
}
.t4m{
    font-size: 3vw;
    line-height: 139%;
    color: #3c3c3c;
    font-weight: 300;
    text-align: justify;
    font-family: "Calibri";
}
</style>