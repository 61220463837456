<template>
  <div class='row clLight px-2 m-0' style='z-index: 10000;position: relative;'>
      <div class="col-12 px-1 py-2" v-for='(card, index) in scInfoCardsTwo' :key='index'>
          <div class="scCard2 clWhite clWhiteDark">
              <div class="d-none d-lg-block">
                <div class="row ">
                  <div class="col-lg-1 p-0 mb-3">
                      <img style='max-width: 80%;' class='d-block mx-auto' src="@/assets/images/sc2.1.png" alt="" srcset="" v-if='card.img == 1'>
                      <img style='max-width: 80%;' class='d-block mx-auto' src="@/assets/images/sc2.2.png" alt="" srcset="" v-if='card.img == 2'>
                      <img style='max-width: 80%;' class='d-block mx-auto' src="@/assets/images/sc2.3.png" alt="" srcset="" v-if='card.img == 3'>
                      <img style='max-width: 80%;' class='d-block mx-auto' src="@/assets/images/sc2.4.png" alt="" srcset="" v-if='card.img == 4'>
                      <img style='max-width: 80%;' class='d-block mx-auto' src="@/assets/images/sc2.6.png" alt="" srcset="" v-if='card.img == 6'>
                  </div>
                  <div class="col-lg-11 p-0 d-none d-lg-block"> <p class="f25 scCard_text my-4 up">{{card.title}}</p> </div>
                  <div class="col-lg-11 p-0  d-lg-none"> <p class="text-center f25 scCard_text my-4 up">{{card.title}}</p> </div>
              </div>
              </div>
              <div class="d-lg-none mb-3">
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.1.png" alt="" srcset="" v-if='card.img == 1'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.2.png" alt="" srcset="" v-if='card.img == 2'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.3.png" alt="" srcset="" v-if='card.img == 3'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.4.png" alt="" srcset="" v-if='card.img == 4'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.6.png" alt="" srcset="" v-if='card.img == 6'>
                <p class="d-inline-block f25 scCard_text scCard_text_title my-2 up" style="    width: 90%;
    font-size: 3.2vw;
    padding-left: 4%;">{{card.title}}</p>
              </div>
              
              <p class="f20 scCard_text" v-if='card.img == 6'>
                  Please <span class="tx_link noDec" @click="gto"> click here </span>  to view the fuse.gold terms and conditions.
                  </p>
              <p class="f20 scCard_text" v-else>{{card.desc}}</p>
          </div>
      </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: "ScCardsSectionTwo",
    computed:{
        ...mapState(['scInfoCardsTwo'])
    },
    methods:{
        gto(){
            this.$router.push({name:"TermsConditions"})
        }
    },
}
</script>

<style scope>
.cs-one{
    margin-left: -4px;
    width: 10%;
    transform: scale(1.5);
}
</style>