<template>
  <div class='row justify-content-center clLight px-2 m-0' style='position: relative;'>
      <div  class="col-lg-4 col-10 col-md-9 px-1 py-2 px-0 m-0" v-for='(card, index) in scCards' :key='index'>
          <div class="scCard clWhite clWhiteDark">
              <div class="row mb-3 p-0 d-none d-lg-block" style="margin-left: -11px;">
                  <div class="col-lg-2 float-left p-0">
                      <img style='width:97%;' class='d-block' src="@/assets/images/sc1.png" alt="" srcset="" v-if='card.img == 1'>
                      <img style='width:97%;' class='d-block' src="@/assets/images/sc2.png" alt="" srcset="" v-if='card.img == 3'>
                      <img style='width:97%;' class='d-block' src="@/assets/images/sc3.png" alt="" srcset="" v-if='card.img == 2'>
                  </div>
                  <div  class="col-lg-10 d-inline-block"> <p class="f25 scCard_text scCard_text_title my-4 up">{{card.title}}</p> </div>
              </div>
              <div class="d-lg-none mb-3">
                      <img class='d-inline-block cs-one' src="@/assets/images/sc1.png" alt="" srcset="" v-if='card.img == 1'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc2.png" alt="" srcset="" v-if='card.img == 3'>
                      <img class='d-inline-block cs-one' src="@/assets/images/sc3.png" alt="" srcset="" v-if='card.img == 2'>
                <p class="d-inline-block f25 scCard_text scCard_text_title my-4 up" style="width: 80%;
    font-size: 4.2vw;
    padding-left: 7%;">{{card.title}}</p>
              </div>

              
              <p style='' class="mb-0 f20 scCard_text scCard_text_dark">{{card.desc}}</p>
          </div>
      </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: "ScCardsSection",
    computed:{
        ...mapState(['scCards'])
    }
}
</script>

<style scoped>
.cs-one{
    margin-left: -9px;
    width: 20%;
    transform: scale(1.5);
}
</style>