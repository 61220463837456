<template>
  <div>
    <nav-bar :wallet='false'></nav-bar>
    <div class="row justify-content-end m-0 p-0">
        <div class="col-12 col-lg-11 m-0 p-0">
            <h1 class="ml-lg-5 ml-4 mt-4 up sc-title">Security & Custody</h1>

            <p class="ml-lg-5 ml-4 sc_subtitle up">PROTECT YOUR PAST, PRESENT AND FUTURE</p>
        </div>
        <div class="col-12 col-lg-11 clLight pr-1 uper">
            <h1 class="m-4 up sc-title text-right">
                VAULTING IN LONDON
            </h1>
            <sc-cards-section></sc-cards-section>
        </div>
        <div class="col-12 px-0 m-0" >
            <picture-container></picture-container>
        </div>
        <div class="col-12 col-lg-10 px-0 my-5 mr-lg-5 px-lg-2 ">
            <div class="row mt-5 mx-1 p-0">
                <div class="col-12  clLight p-0 m-0">
                    <h1 class="m-4 up sc-title text-right">
                        VAULT STORAGE BENEFITS
                    </h1>
                        <sc-cards-section-two></sc-cards-section-two>
                </div>

            </div>
        </div>
        <div class="col-12 px-lg-4 p-0 mb-4">
            <sc-card-register></sc-card-register>
        </div>
    </div>
    <footer-section></footer-section>

  </div>
</template>

<script>
import ScCardsSection from '@/components/ScCardsSection.vue'
import ScCardRegister from '@/components/ScCardRegister.vue'
import ScCardsSectionTwo from '@/components/ScCardsSectionTwo.vue'
import PictureContainer from '@/components/PictureContainer.vue'
// import ScInfoCard from '@/components/ScInfoCard.vue'
import {mapState} from 'vuex';
export default {
    name: "SecurityCustody",
    computed:{
        ...mapState(['scInfoCard'])
    },
    components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),
        ScCardsSectionTwo,
        // ScInfoCard,
        PictureContainer,
        ScCardRegister,
        ScCardsSection,
        },
}
</script>

<style>

</style>