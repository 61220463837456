<template>
  <div>
      <div class="main-back d-none d-lg-block" style='height: 600px;'>
          <img src="@/assets/images/sands.png" style='max-width:100vw;' alt="" class="traget-back">
      </div>
      <div class="d-lg-none">
          <img src="@/assets/images/sands.webp" width="100%" height="auto">

      </div>
  </div>
</template>

<script>
export default {
    name: "PictureContainer"
}
</script>

<style>

</style>